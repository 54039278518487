<template>
  <LinkResolver
    v-if="banner"
    :id="banner.ID"
    :link="banner.url"
    data-test-id="fullwidth-banner"
    class="fullwidth-banner"
    :style="`--bg-gradient:  linear-gradient(90deg, ${banner.bg_gradient_colors}); --bg-gradient-mobile: linear-gradient(90deg, ${banner.bg_gradient_colors_mobile})`"
  >
    <ImageLazy
      v-if="bannerImage"
      :key="isSm"
      class="fullwidth-banner-image"
      data-test-id="fullwidth-banner-image"
      :src="bannerImage"
      :width="isSm ? '720' : '2880'"
      max-height="170"
      :background-image="true"
      :quality="100"
      :bg-size="banner.bg_size || 'cover'"
    />
    <div v-if="banner" class="fullwidth-banner-link" data-test-id="fullwidth-banner-link">
      <div class="fullwidth-banner-link-content" data-test-id="fullwidth-banner-content">
        <div
          v-if="title"
          class="fullwidth-banner-link-content-title"
          :class="{ 'fullwidth-banner-link-content-title-hidden': banner.hidden_title }"
          data-test-id="fullwidth-banner-title"
          v-html="title"
        />
        <h2
          v-if="subtitle"
          class="fullwidth-banner-link-content-subtitle"
          data-test-id="fullwidth-banner-subtitle"
          v-html="subtitle"
        />
      </div>
    </div>
  </LinkResolver>
</template>

<script setup>
import LinkResolver from '@/components/LinkResolver/LinkResolver'

const props = defineProps({
  banner: { type: Object, default: () => {} },
  lazy: { type: Boolean, default: true },
})
provide('lazy', props.lazy)

const { isSm } = useDeviceSize()

const title = computed(() => {
  // Richtext if object from SB, else string
  if (props.banner?.title) {
    if (typeof props.banner?.title === 'object') {
      return renderRichText(props.banner.title)
    } else if (typeof props.banner?.title === 'string') {
      return props.banner.title
    }
  }
  return null
})

const subtitle = computed(() => {
  // Richtext if object from SB, else string
  if (props.banner?.subtitle) {
    if (typeof props.banner?.subtitle === 'object') {
      return renderRichText(props.banner.subtitle)
    } else if (typeof props.banner?.subtitle === 'string') {
      return props.banner.subtitle
    }
  }
  return null
})

const bannerImage = computed(() => {
  if (isSm.value && props.banner.image_mobile_url) {
    return props.banner.image_mobile_url
  } else if (isSm.value && props.banner.image_mobile?.filename) {
    return props.banner.image_mobile.filename
  } else if (props.banner.image_url) {
    return props.banner.image_url
  } else {
    return props.banner.image.filename
  }
})
</script>

<style lang="scss">
.fullwidth-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $spacing-xs;
  display: block;
  height: 170px;
  padding: $spacing-xl;
  position: relative;
  text-decoration: none;
  width: 100%;
  background: var(--bg-gradient);
  @include mq($mq-sm) {
    background: var(--bg-gradient-mobile);
  }
  &-image {
    width: 100% !important;
    position: absolute !important;
    height: 170px;
    top: 0;
    left: 0;
    border-radius: $spacing-xs;
  }
  &-link {
    position: relative;
    text-decoration: none;
    h1,
    p {
      color: var(--white);
    }
    &-content {
      &-title {
        h1,
        h2 {
          font-size: pxToRem(30px);
        }
        &-hidden {
          opacity: 0;
        }
      }
      &-subtitle {
        font-weight: 400;
      }
    }
  }
  @include mq($mq-sm) {
    width: calc(100% + $spacing-xl);
    margin-left: -$spacing-md;
    margin-top: 0 !important;
    border-radius: 0;
    &-content {
      max-width: calc(100% * 2 / 3);
    }
    h1,
    h2 {
      font-size: pxToRem(15px);
    }
  }

  &.home-banner {
    @include mq($mq-sm) {
      h1,
      h2 {
        font-size: pxToRem(17px);
      }
    }
    @include mq($mq-xs) {
      h1,
      h2 {
        max-width: 65%;
        font-size: pxToRem(15px);
      }
    }
    @include mq($mq-xxs) {
      h1,
      h2 {
        max-width: 70%;
        font-size: pxToRem(14px);
      }
    }
  }
}
</style>
